import React,{useState, useRef} from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";
import './Modal.scss'
import { Formik, Form, Field } from 'formik';
const Modal = (props) => {
    const captchaRef = useRef();
    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Mínimo 2 caracteres').max(100, 'Máximo 100 caracteres').required('Campo requerido'),
        phone: Yup.string().min(10,'Se esperan al menos 10 dígitos').max(10,'Se esperan al máximo 10 dígitos').required('Campo requerido'),
        contactTime: Yup.string().min(2,'Se esperan al menos 10 dígitos').max(50,'Se esperan al máximo 50 dígitos').required('Campo requerido'),
        recaptcha: Yup.string().required("Llenar captcha")
    });
    return ( 
        <div className='contenedor_modal'>
            <p onClick={()=>{props.setModal(false)}} className='closed'>X</p>
            <div className='contenedor_modal_form'>
                <img src="/assets/Home/iconos-logos/img_logo_puntavela.svg" alt="" />
                <h2>¿DESEAS MÁS INFORMACIÓN?</h2>
                <p>Completa tus datos y, en breve, uno de nuestros asesores te contactará para brindarte más detalles sobre nuestras residencias.</p>
                <Formik
                    initialValues={{
                        name: '',
                        phone: '',
                        email: '',
                        contactTime: '',
                        recaptcha: ''
                    }}
                    validationSchema={SignupSchema}
                    onSubmit = { async (values,{ resetForm })  =>  {
                            const response = await axios.post(process.env.REACT_APP_PROTOTYPE_CONTACT_ENDPOINT, values)
                            
                            if(response.data.success){
                                resetForm();
                                captchaRef.current?.reset()
                                swal("Enviado", "Los datos han sido enviados correctamente", "success");
                                var link = document.createElement('a');
                                link.href = '/assets/Home/Brochure_PuntaVela_opt.pdf'
                                link.target ='_blank'
                                link.download = 'Brochure.pdf';
                                link.dispatchEvent(new MouseEvent('click'));
                            }else{
                                if(response.data.error){
                                    swal("Error", "Ocurrio un problema al enviar el correo", "error");
                                }
                            }
                    }}
                    >
                    {({ errors, touched, handleSubmit,setFieldValue}) => (
                        <Form onSubmit={handleSubmit}>
                            <Field name="name" placeholder="Nombre"/>
                                {errors.name && touched.name ? (
                                    <div className='error'>{errors.name}</div>
                                ) : null}
                            <Field name="phone" type="number" placeholder="Teléfono"/>
                                {errors.phone && touched.phone ? (
                                    <div className='error'>{errors.phone}</div>
                                ) : null}
                            <Field name="email" type="email" placeholder="Correo electrónico (opcional)"/>
                            <Field name="contactTime" placeholder="Horario de contacto preferido:"/>
                                {errors.contactTime && touched.contactTime ? (
                                    <div className='error'>{errors.contactTime}</div>
                                ) : null}
                                {errors.email && touched.email ? <div className='error'>{errors.email}</div> : null}
                            <Field name="recaptcha">
                                {
                                    ({meta}) => {
                                        const handleChange = (value) => setFieldValue("recaptcha",value)
                                        return (
                                            <>
                                                
                                                <ReCAPTCHA
                                                    ref={captchaRef}
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    onChange={handleChange}
                                                />
                                                { meta.error && meta.touched ? (
                                                    <div className='error'>{meta.error}</div>
                                                ) : null}
                                            
                                            </>)
                                    }
                                    
                                }
                            </Field>
                            <p>Al enviar acepta nuestra <a onClick={()=>{props.setModalAviso(true)}}>Politica de privacidad</a></p>
                            <button className='button_send' type="submit">Enviar</button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
     );
}
 
export default Modal;