import React, { useState, useEffect, useCallback } from "react";
import "./Principal.scss";
import Modal from "../Modal/Modal"; // Importa tu componente Modal

const Principal = () => {
  const [y, setY] = useState(window.scrollY);
  const [showStiky, setShowStiky] = useState("ocultar");
  const [modalOpen, setModalOpen] = useState(false); // Estado para abrir/cerrar modal

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if (window.scrollY <= 100) {
          setShowStiky("ocultar");
        }
      } else if (y < window.scrollY) {
        if (window.scrollY >= 100) {
          setShowStiky("mostrar");
        }
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div className="contenedor__principal">
      <div className="contenedor__principal_carousel">
        <div className="contenedor__principal_carousel__slider">
          <img
            src="./assets/Home/img_hero_punta-vela.jpg"
            alt="Punta vela exterior"
          />
          <div className="texto-superpuesto">
            <h1>
              Tu hogar ideal en una de las mejores zonas de Ciudad Juárez
            </h1>
            <p>
              Disfruta de un entorno seguro y de alta plusvalía en este exclusivo
              clúster de 63 residencias que te ofrece comodidad, tranquilidad y
              una excelente inversión en el corazón de la ciudad.
            </p>
            <a href="https://disponibilidad.puntavela-juarez.com/">
              Ver disponibilidad
            </a>
            <div class="botonbrochure">
            <p onClick={() => setModalOpen(true)}>¡AGENDA TU CITA!</p> {/* Abre el modal */}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_info">
        <div className="icon-scroll"></div>
        <div className="whatsapp">
          <div className={showStiky}>
            <a
              target={"_blank"}
              href="https://api.whatsapp.com/send?phone=+526566385985&text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PuntaVela"
            >
              <img
                src="/assets/Home/iconos-logos/svg-icon-whatsapp.svg"
                alt="Whatsapp"
              />
            </a>
            
          </div>
        </div>
        <div className="texto">
          <p>CASAS RESIDENCIALES PREMIUM</p>
        </div>
      </div>
      {modalOpen && <Modal setModal={setModalOpen} />} {/* Renderiza el modal */}
    </div>
  );
};

export default Principal;
